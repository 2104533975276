$main-color: #2d85be !default;
$brand-primary: $main-color !default;
$info-color: $main-color !default;
$info-states-color: darken($info-color, 10%) !default;

// bower:scss
@import "bower_components/font-awesome/scss/font-awesome.scss";
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "bower_components/select2/src/scss/core.scss";
// endbower

@font-face {
	font-family: 'Pe-icon-7-stroke';
	src:url('../fonts/Pe-icon-7-stroke.eot?d7yf1v');
	src:url('../fonts/Pe-icon-7-stroke.eot?#iefixd7yf1v') format('embedded-opentype'),
		url('../fonts/Pe-icon-7-stroke.woff?d7yf1v') format('woff'),
		url('../fonts/Pe-icon-7-stroke.ttf?d7yf1v') format('truetype'),
		url('../fonts/Pe-icon-7-stroke.svg?d7yf1v#Pe-icon-7-stroke') format('svg');
	font-weight: normal;
	font-style: normal;
}




@import "../assets/css/animate.min.css";
@import "../assets/css/demo.css";
@import "../assets/css/pe-icon-7-stroke.css";
@import "dashboard/light-bootstrap-dashboard.scss";
@import "login";
data-inputmask

html, body{
	overflow: hidden;
	height: 100vh;
}

.td-check{
    width: 50px;
}

.td-actions{
    width: 100px;
}

thead td{
    font-weight: bold;
}

tbody tr:hover{
	transition: all 0.2s;
	background-color: #eee;
}

.sidebar-background{
    opacity: 0.8;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td{
	padding: 12px !important;
}

.card .header{
	padding: 20px 30px 0;
}

.card .content{
	padding: 20px 30px;
}

.table-full-width{
	margin: 0;
	width: 100%;
}

.dataTable{
	width: 100% !important;
}

.nav-tabs{
	border: 0;
	margin-bottom: 2px;
	li{
		a{
			padding: 10px 30px;
			font-size: 17px;
			background: transparent !important;
			border-top: 0 !important;
			border-left: 0 !important;
			border-right: 0 !important;
			border-bottom: 3px solid transparent !important;
			color: #999;
		}
	}

	.active a{
		background: transparent !important;
		border-bottom: 3px solid $main-color !important;
		color: $main-color !important;
	}
}

.card{
	box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1);
	border-radius: 0;
}

.right-cont{
	input, select, a{
		display: inline-block;
		float: right;
		margin: 0 0 0 2%;
		height: 35px;
	}

	select{
		width: 18%;
	}

	input{
		width: 40%;
	}

	@media screen and (max-width: 768px){
		input, select, a{
			width: 100%;
			margin: 10px 0;
		}
	}
}

.btn:not(.btn-simple){
	border-width: 1px;
	background-color: $main-color;
	color: #fff;
}

.card form [class*="col-"]:first-child,
.card form [class*="col-"]{
	padding-left: 15px;
}

.btn-gray{
	background-color: #eee !important;
	border-color: #eee !important;
	color: #333 !important;
	margin-right: 10px !important;
}

.modal-backdrop.in{
	opacity: 0.85;
}

.modal-content{
	border-radius: 0;
	padding: 35px;

	.modal-action{
		margin-top: 15px;
	}
}

.bootstrap-select {
	.btn{
		background: #fff;
		border: 1px solid #E3E3E3;
	}
	.open .form-control{
		border-radius: 0;
		border: 1px solid #E3E3E3;
	}
	.filter-option{
		color: #000;
	}
}

.dropdown-menu{
	border-radius: 5px;
}

.alert{
	position: relative;
	padding: 20px 15px;
}

.alert-fixed{
	display: inline-block; margin: 0px auto; position: fixed; transition: all 0.5s ease-in-out 0s; z-index: 1031; top: 20px; right: 20px;
	button{
		position: absolute; right: 10px; top: 50%; margin-top: -13px; z-index: 1033;
	}
}

.alert-success {
    background-color: $main-color;
}

.table > thead > tr > th{
	font-weight: bold;
}

a{
	font-weight: bold;
}

.form-group{
    display: inline-block;
    width: 100%;
	a.btn{
		margin-left: 10px;
	}
	label{
		display: inline-block;
		width: 100%;
	}
}

.duplicate-row{
	margin-top: 5px;
	margin-left: 10px;
}

.form-group .row:nth-child(2){
	.row-minus{
		display: none;
	}
}

.form-group{
	& > .row{
		margin: 0;
		padding: 10px 0;
		background: #eee;
		.btn-gray{
			background: #ddd !important;
		}
	}
}

.card .category, .card label{
	color: #666;
}

.data-into-tab{
	float: left;
	width: 40%;
	margin-right: 1%;
}

.select2-selection{
	border: 1px solid #E3E3E3 !important;
	min-height: 40px !important;
	& > span{
		margin-top: 5px !important;
	}
}

.select2-container--default .select2-selection--multiple .select2-selection__choice{
	margin-top: 9px;
}

.panel{
	margin-top: 20px;
	border: 0px solid #fff !important;
	.panel-heading{
		border-radius: 0;
		background: transparent;
		border: 0px solid #fff !important;
		margin-top: 20px;
		margin-bottom: 7px;
		box-shadow: 0 0 5px rgba(#000,0.15);
		h4{
			color: $main-color;
		}
	}
	.panel-body{
		padding: 0;
		margin: 0;
		border: 0px solid #fff !important;
	}

}

label.radio-custom{
	input{
		margin-right: 10px;
	}
}

.form-group label{
	font-weight: bold;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child::before, table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child::before{
	top: 15px;
	left: -19px;
}

.nav-tabs > li > a,
.nav-tabs > li > a,
.nav-tabs > li > a{
	@media screen and (max-width: 768px){
		&{
			font-size: 12px;
			padding: 10px;
		}
	}
}

.main-content{
	.container-fluid{
		@media screen and (max-width: 768px){
			padding: 0;
		}

		.card .content,
		.card .header{
			@media screen and (max-width: 768px){
				padding: 10px 15px;
			}
		}
	}
}

.form-group{
	.btn{
		margin-top: 5px;
		margin-bottom: 5px;
		@media screen and (max-width: 480px){
			width: 100%;
		}
	}
}

.navbar.navbar-default{
	@media screen and (max-width: 991px){
		@include icon-gradient(darken(#333,0%), darken(#333,10%));
	}
}
