.login{
	overflow: scroll;
	height: 100vh;
	padding-top: 10vh;
	@include icon-gradient(darken(#333,0%), darken(#333,10%));
	.card-container.card {
	    max-width: 350px;
	    padding: 40px 40px;
	}

	.btn {
	    font-weight: 700;
	    height: 36px;
	    -moz-user-select: none;
	    -webkit-user-select: none;
	    user-select: none;
	    cursor: default;
	}

	/*
	 * Card component
	 */
	.card {
	    background-color: #fff;
	    /* just in case there no content*/
	    padding: 20px 25px 30px;
	    margin: 0 auto 25px;
	    margin-top: 50px;
	    /* shadows and rounded borders */
	    -moz-border-radius: 2px;
	    -webkit-border-radius: 2px;
	    border-radius: 2px;
	    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
	}

	.profile-img-card {
	    width: 96px;
	    height: 96px;
	    margin: 0 auto 10px;
	    display: block;
	    -moz-border-radius: 50%;
	    -webkit-border-radius: 50%;
	    border-radius: 50%;
	}

	/*
	 * Form styles
	 */
	.profile-name-card {
	    font-size: 16px;
	    font-weight: bold;
	    text-align: center;
	    margin: 10px 0 0;
	    min-height: 1em;
	}

	.reauth-email {
	    display: block;
	    color: #404040;
	    line-height: 2;
	    margin-bottom: 10px;
	    font-size: 14px;
	    text-align: center;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    -moz-box-sizing: border-box;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	}

	.form-signin #inputEmail,
	.form-signin #inputPassword {
	    direction: ltr;
	    height: 44px;
	    font-size: 16px;
	}

	.form-signin input[type=email],
	.form-signin input[type=password],
	.form-signin input[type=text],
	.form-signin button {
	    width: 100%;
	    display: block;
	    margin-bottom: 10px;
	    z-index: 1;
	    position: relative;
	    -moz-box-sizing: border-box;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	}

	a.btn, input[type=submit]{
		padding: 10px !important;
		height: auto !important;
		margin-bottom: 10px;
	}

	.form-signin .form-control:focus {
	    border-color: $main-color;
	    outline: 0;
	    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px $main-color;
	    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px $main-color;
	}

	.btn.btn-signin {
	    /*background-color: #4d90fe; */
	    background-color: $main-color;
	    /* background-color: linear-gradient($main-color, darken($main-color,5%));*/
	    padding: 0px;
	    font-weight: 700;
	    font-size: 14px;
	    height: 36px;
	    -moz-border-radius: 3px;
	    -webkit-border-radius: 3px;
	    border-radius: 3px;
	    border: none;
	    -o-transition: all 0.218s;
	    -moz-transition: all 0.218s;
	    -webkit-transition: all 0.218s;
	    transition: all 0.218s;
	}

	.btn.btn-signin:hover,
	.btn.btn-signin:active,
	.btn.btn-signin:focus {
	    background-color: darken($main-color,5%);
	}

	.forgot-password {
	    color: $main-color;
	}

	.forgot-password:hover,
	.forgot-password:active,
	.forgot-password:focus{
	    color: darken($main-color,5%);
	}

	label{
		padding-left: 0;
	}
}
